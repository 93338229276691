.fav-count {
  padding-left: 4px;
}

.fav-button {
  button {
    @include square_button(150px);

    &:focus {
      outline: 0;
    }
  }
}

.timeline-fav-button {
  text-align: center;
  margin-bottom: 10px;

  button {
    background: white;
    padding: 0.4rem;
    font-size: 1.3rem;
    text-decoration: none;
    color: $color_primary;
    text-align: center;
    transition: .3s;
    cursor: pointer;

    &:hover {
      background: $color_primary;
      color: white;
    }

    &:focus {
      outline: 0;
    }
  }
}

.comment-fav-button {
  text-align: center;

  button {
    min-width: 30px;
    background: white;
    padding: 0 0.5rem;
    text-decoration: none;
    color: $color_primary;
    text-align: center;
    transition: .3s;
    cursor: pointer;

    &:hover {
      background: $color_primary;
      color: white;
    }

    &:focus {
      outline: 0;
    }
  }
}


.fav-btn-wrapper {
  @include flex_center();
}

.fav-popup-wrapper {
  position: relative;
  
  .fav-popup-button {
    margin-left: 4px;

    width: 20px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .fav-popup {
    width: 160px;
    max-height: 200px;

    // overflow-y: scroll;
    overflow-x: hidden;

    padding: 5px 0;
    text-align: left;
    font-size: 1.1rem;
    background: #333;
    border-radius: 4px;
    color: white;

    position: absolute;
    display: inline-block;
    z-index: 10;
    top: 35px;
    right: 0;

    a {
      display: block;
      color: white;
      padding: 5px 10px;

      &:hover {
        cursor: pointer;
        text-decoration: none;
        background: gray;
      }
    }
  }
}