.users-list {
  @include clearfix();
  padding-bottom: 40px;

  h2 {
    margin: 20px 20px 30px 20px;
    font-size: 1.5rem;
  }

  .user-wrapper {
    float: left;
    width: 50%;
    padding: 10px;
    margin-bottom: 20px;

    &:nth-child(2n+1) {
      clear: both;
    }

    @media screen and (max-width: $break_point) {
      width: 100%;
      clear: both;
    }
  }

  .user {
    background: white;
    padding: 10px;

    .avator {
      float: left;
      width: 120px;
      img {
        width: 100%;
      }
    }
    .info {
      margin-left: 130px;
      @include clearfix();
      .name {
        a {
          color: black;
        }
        font-weight: 600;
        font-size: 1.4rem;
        margin-bottom: 4px;
      }
      .status {
        font-size: 1.1rem;
      }  
      .body {
        margin-top: 4px;
        font-size: 1.1rem;
      }
    }
  }

  .artist {
    background: white;
    padding: 10px;
    border-radius: 4px;

    .name {
      a {
        color: black;
      }
      font-weight: 600;
      font-size: 1.4rem;
    }
  }

  .action-link {
    margin-left: 10px;
    margin-top: -10px;  
    a {
        @include square_button(140px);
        display: block;
      }
  }

  .request-button{
    display: block;
    margin-top: 10px;
    @include square_button(150px);
  }
}