
.works-wrapper {
  margin: 20px;
  margin-top: $base_margin;
  @media screen and (max-width: $break_point) {
    margin: 20px;
  }
}

.works__header {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: $break_point) {
    flex-direction: column;
  }

  .artboard {
    width: 60%;
    @media screen and (max-width: $break_point) {
      width: 100%;
    }

    .artist {
      font-weight: 600;
      font-size: 1.5rem;
      margin-bottom: 5px;
    }

    .image {
      border: 1px solid #ddd;
      padding: 30px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }

    .image-slider {
      padding-top: 15px;
      // text-align: center;
      img {
        width: 80px;
        cursor: pointer;
      }
      img + img {
        margin-left: 10px;
      }
    }

    .title {        
      font-size: 1.4rem;
      margin-top: 5px;
      text-align: right;
    }
  }

  .works-spacer {
    flex-grow: 1;
    display: block;
    @media screen and (max-width: $break_point) {
      display: none;
    }
  }
}

.works-profile {
  margin-top: 30px;
  width: 35%;

  @media screen and (max-width: $break_point) {
    display: none;
  }
  @media screen and (min-width: $break_point) {
    display: block;
  }
} 
.works-mobile-profile {
  margin-top: 30px;
  width: 100%;

  @media screen and (max-width: $break_point) {
    display: block;
  }
  @media screen and (min-width: $break_point) {
    display: none;
  }
} 


.works__description {
  font-size: 1.6rem;
  line-height: 1.8;
}

.works__info {
  margin-top: 20px;
}

.info-table {
  th {
    padding: 8px 0;
    vertical-align: top;
  }
  td {
    padding: 8px 0;
    vertical-align: top;
  }
}

.contact {
  @include flex_center();
  margin-top: 30px;
  a {
    width: 160px;
  }
}


.criticisms {
  .add-criticism {
    a {
      margin: 0 auto;
      display: block;
      @include square_button(100px);
      width: 180px;
    }
  }
}


.works__comments {
  .comment {
    margin-top: 40px;

    .info {
      @include flex_center();
      height: 30px;
      font-size: 1.1rem;  

      img {
        width: 20px;
        border-radius: 10px;          
        margin-right: 6px;
      }
      .name {
      }
      .date {
        flex: 1 1 auto;
        text-align: right;
      }
    }
    .body {
      background: $color_background;
      padding: 12px 20px;
      margin-top: 10px;
    }
  }

  .form_area {
    width: 100%;
    text-align: center;
    margin-top: $base_margin;
  }
}


.copy-from-image {
  text-align: center;
  margin-top: 20px;

  img {
    max-height: 200px;
  }
}

.copy-button {
  margin-top: 10px;
  input {
    @include square_button(100px);
    &:focus {
      outline: 0;
    }
  }
}



@media screen and (max-width: $break_point) {
  .work-items {
    @include clearfix();
    margin-bottom: $base_margin;
  
    .work-item {
      display: flex;
      width: 100%;
      overflow: hidden;
      margin-bottom: 40px;
      
      a {
        color: $color_text_main;
      }
      .thumbnail {
        width: 30%;
        img {
          width: 100%;
        }
      }
      .info {
        margin-left: 16px;
        flex: 1;
        .title {
          font-weight: 600;
          font-size: 1.4rem;
        }
        .body {
          font-size: 1.2rem;
          color: $color_text_sub;
        }
      }
    }
  }
}

@media screen and (min-width: $break_point) {
  .work-items {
    @include clearfix();    
    margin-bottom: $base_margin;
    
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    .work-item {
      width: 33.33%;
      padding: 20px;
      padding-bottom: 40px;
      overflow: hidden;
      
      a {
        color: $color_text_main;
      }
      img {
        width: 100%;
      }
      .info {
        .title {
          font-weight: 600;
          font-size: 1.4rem;
        }
        .body {
          font-size: 1.2rem;
          color: $color_text_sub;
        }
      }
    }
  }
}
