//-------------------------------------
//  Reset a.k.a @include global-reset
//-------------------------------------
// Based on Eric Meyer's reset 2.0 Global reset rules. 
// For more specific resets, use the reset mixins provided below
// http://meyerweb.com/eric/tools/css/reset/

html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, address,
del, em, img, small, strong, b, u, i, hr,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
button,
time, mark, audio, video, input, select {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}
html {
  line-height: 1;
}
ol,ul,li{
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption, th, td {
  text-align: inherit;
  font-weight: inherit;
  vertical-align: middle;
}
q, blockquote {
  quotes: none;
}
a img {
  border: none;
}
article, aside, details, figure, footer, header, menu, nav, section, summary {
  display: block;
}
div:focus{
  outline: none;
  background: transparent;
}

textarea{
  resize: none;
}