.sub-navigation-menu {
  padding: 40px 0 55px 0;
  text-align: center;

  li {
    display: inline-block;
    padding: 4px 0; 
    font-size: 1.5rem;
    
    min-width: 120px;
    @media screen and (max-width: $break_point) {
      min-width: 80px;
    }

    &.active, .active {
      font-weight: 600;
    }

    &.title {
      color: $color_text_sub;
      font-size: 1.4rem;
    }

    a {
      color: $color_text_main;
    }
  }
}