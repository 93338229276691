// Site Layout
$break_point: 769px;
$break_point_narrow: 600px;

$site_max_width: 1080px;
$header_height: 88px;
$sp_header_height: 56px;
$base_margin: 60px;

// Color
$color_background: #F0F0F0;
$color_primary: #84C0DA;
$color_text_main: #2c2e31;
$color_text_sub: #707980;
$color_border: #DDDDDD;

// z-index
$zindex_header: 100;
$zindex_drawer_close: 101;
$zindex_drawer_content: 102;



@mixin clearfix {
  zoom: 1;
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin placeholderColor($color) {
  &:placeholder-shown {
      color: $color;
  }
  &::-webkit-input-placeholder {
      color:$color;
  }
  &:-moz-placeholder {
      color:$color;
      opacity: 1;
  }
  &::-moz-placeholder {
      color:$color;
      opacity: 1;
  }
  &:-ms-input-placeholder {
      color:$color;
  }
}

@mixin flex_center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex_v_center() {
  display: flex;
  align-items: center;
}

@mixin site_max_width() {
  width: 100%;
  max-width: $site_max_width;
  margin: 0 auto;
}

@mixin clear_site_max_width() {
  max-width: 100%;
  margin: 0;
}

@mixin background_image($width, $height) {
  width: $width;
  height: $height;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin square_button($width) {
  background: white;
  padding: 0.4rem 2.4rem;
  width: $width;
  font-size: 1.3rem;
  text-decoration: none;
  color: $color_primary;
  border: solid 1px $color_primary;
  text-align: center;
  transition: .3s;
  cursor: pointer;

  &:hover {
    background: $color_primary;
    color: white;
  }
}

@mixin background($color) {
  background: $color;
  &:active, &:hover, &:focus { // for ie11
    background: $color;
  }
}