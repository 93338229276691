.follows {
  border-bottom: 1px dashed #eee;

  padding: 10px;
  margin-right: 20px;


  @include clearfix();

  .avator {
    float: left;
    margin-top: 5px;

    img {
      width: 50px;

    }

    
  }
  .name {
    margin-left: 70px;
    font-size: 1.5rem;
    
  }
  .body {
    margin-left: 70px;
    font-size: 1.3rem;

  }
}