.artist-list {
  @include clearfix();
  margin: 0 8px;
  
  .artist-card-wrapper {
    float: left;
    padding-bottom: 16px;

    @media screen and (min-width: $break-point) {
      width: 33.33%;
      &:nth-child(3n+1) {
        clear: both;
      }
    }
    @media screen and (max-width: $break-point) {
      width: 100%;
      clear: both;
    }

    .artist-card {
      width: calc(100% - 16px);
      margin: 0 auto;
      background: white;
      padding: 16px 10px;
      overflow: hidden;

      &__name {
        text-align: center;
        a {
          font-size: 1.5rem;
        }
        div {
          font-size: 1.2rem;
        }
      }
      
      &__follow {
        margin-top: 20px;
        text-align: center;
        font-size: 1.3rem;
      }
      
      &__work {
        display:flex;
        justify-content: center;
        margin: 5px 0;

        .work{
          width: 33.33%;  
        }
        
        .work__image {
          img {
            width: 95%;
            height: 95%;
          }
        } 
      }
          
    }
  }
}