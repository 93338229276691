
$masonry_gutter: 60px;
$masonry_gutter_sp: 20px;

.background {
  background: $color_background;
}

.timeline-wrapper {
  background: $color_background;
}

#timeline {
  width: calc(100% - #{$masonry_gutter});
  margin-left: $masonry_gutter;

  @media screen and (max-width: $break-point) {
    width: calc(100% - #{$masonry_gutter_sp});
    margin-left: $masonry_gutter_sp;
  }
}

.showmore-wrapper {
  @include flex_center();
  clear: both;

  .showmore {
    margin: 40px 0;
    margin-left: - $masonry_gutter;
    @media screen and (max-width: $break-point) {
      margin-left: - $masonry_gutter_sp;
    }
  }
}

.more-works {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  text-align: right; 
  font-size: 1.3rem;
  a {
    color: $color_text_sub;
  }
}


@import "timeline_collection";
@import "timeline_follow";
