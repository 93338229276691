.timiline-hashtag-header {
  margin: 40px 10px 20px 10px;
  font-size: 1.3rem;
}

.timeline-filter {
  text-align: center; 
  margin: 40px 0 0 0;

  &__box {
    padding: 5px;

    fieldset {
      margin: 10px;
      text-align: left;
    }
    ledgend {
      display: block;
      font-size: 1.2rem;
      color: gray;
      margin-bottom: 4px;
    }

    .links {
      a {
        display: inline-block;

        @media screen and (min-width: $break_point) {
          width: 90px;
          padding: 4px;
        }
        @media screen and (max-width: $break_point) {
          padding: 4px 12px;
        }

        font-size: 1.2rem;
        border-radius: 20px;
        margin-right: 0px;
        margin-bottom: 4px;
        text-align: center;
        color: black;
        text-decoration: none;
    
        &:hover {
          background: $color_background;
        }
      }
    
      .active {
        background: $color_background;
      }
    }
  }
}