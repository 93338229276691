.help_file {
  @include flex_center();
  margin-bottom: 40px;
  
  .image {
    width: 50%;
    height: 240px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border: 1px solid #ccc;
    }
  }

  .value {
    margin-left: 20px;
    input {
      background: #efefef;
      padding: 4px;
    }
  }
}

.terms {
  .contents {
    .block {
      img {
        max-width: 100%;
        border: 1px solid #ccc;
        padding: 10px;
      }
    }
  }
}