.site-container {
  // ie11 bug https://yochix2.com/ie-flex-stickyfooter/
  display: flex;
  flex-direction: column;

  margin-top: $header_height;
  @media screen and (max-width: $break_point) {
    margin-top: $sp_header_height;
  }  
}

.main-container {
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - #{$header_height});
  @media screen and (max-width: $break_point) {
    min-height: calc(100vh - #{$sp_header_height});
  }  

  .main {
    flex: 1 1 auto;
  }
}


.max-width {
  @include site_max_width();
  @media screen and (max-width: $break_point) {
    @include clear_site_max_width();
  }
}

.max-width.pad40 {
  padding: 0 40px;
}

.max-width.pad20 {
  padding: 0 20px;
}


.form-container {
  @include site_max_width();
  padding: 0 40px;

  @media screen and (max-width: $break_point) {
    @include clear_site_max_width();
    padding: 0;
  }

  .switch_language{
    margin-top: 20px;
  }
  h1 {
    text-align: left;

    @media screen and (max-width: $break_point) {
      text-align: center;
      border: 0;
    }
  }

  .form_errors {
    @media screen and (max-width: $break_point) {
      padding: 0 20px;
    }
  }
}