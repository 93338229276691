
.col-timeline-item {   
  float: left;

  @media screen and (min-width: $break-point) {
    width: calc(33.33% - #{$masonry_gutter});
    margin-right: $masonry_gutter;
    margin-bottom: $masonry_gutter - 20px;
    &:nth-child(3n+1) {
      clear: both;
    }
  }

  @media screen and (max-width: $break-point) {
    width: calc(50% - #{$masonry_gutter_sp});
    margin-right: $masonry_gutter_sp;
    margin-bottom: $masonry_gutter_sp;
    &:nth-child(2n+1) {
      clear: both;
    }
  }
}

.col-timeline-item {
  text-align: center;
  background: white;

  border: solid 1px #000000;
  border-top: 0;

  a {
    color: $color_text_main;
  }

  table {
    border-collapse: collapse;
    background: white;
    width: 100%;

    th {
      border: solid 1px #000000;
      border-left: 0;
      width: 30%;
      font-size: 1.4rem;
    }
    td {
      border: solid 1px #000000;
      border-right: 0;
      padding: 8px;
    }
  }

  .image-wrapper {
    position: relative;
    img {
      width: 100%;
      padding: 16px;
      padding-bottom: 10px;
    }
    .status {
      position: absolute;
      bottom: 0;
      right: 0;
      background: white;
      padding: 2px;
    }
  }

  .title-wrapper {
    .title {
      font-size: 1.5rem;
      color: $color_text_main;
      word-break: break-all;
    }  
  }

  .body, .date {
    font-size: 1.4rem;
  }
}

