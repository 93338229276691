.flash-notice {
  text-align: center;
  margin: 0 auto;
  background: #FBF7DC;
  color: #907B52;
  font-size: 1.6rem;
  padding: 8px;
  margin: 8px;
  border-radius: 4px;
}
