input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.flex_fields {
  @include flex_v_center();
}

.form_area {
  font-size: 1.4rem;
  @include background($color_background);
  width: 100%;
  padding: 20px;
}

.uploaded_image {
  img {
    width: 140px;
  }
}

.field {
  margin: 0 0 30px; 
  clear: both;

  .required {
    float: left;
    color: red;
    padding-right: 5px;
  }

  label {
    display: block;
    font-size: 1.4rem;
    margin: 4px 0;
  }

  input {
    @include placeholderColor(#ccc);
    width: 100%;
    border:0; 
    padding: 10px;
    font-size: 1.6rem; 
    background: white;

    &[type="checkbox"] {
      width: 20px;
    }
    &.readonly {
      background: $color_background;
    }
  
  }

  textarea {
    width: calc(100% - 20px);
    border:0;
    font-size: 1.6rem; 
    background: white;
    margin-bottom: 3px;
    padding: 10px;
    &::placeholder {
      color: #ccc;  
    }
  }
}

input[type="submit"],
input[type="button"] {
    @include square_button(200px);
}


select {
  background: white;
  height: 40px;
  min-width: 30%;
}

.form_errors {
  color: red;
  font-size: 1.5rem;
  li {
    list-style-type: disc;
    &:last-child {
      margin-bottom: $base_margin / 2;
    }
  }
}

.form_error {
  color: red;
  font-size: 1.5rem;
}

