.crop-wrapper {
  max-height: 400px;
  
  @media screen and (max-width: $break_point) {
    max-height: 200px;
  }

  img {
    max-width: 100%;
  }
  
  .cropper-container {
    margin-top: 30px;
  }
}
