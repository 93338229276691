#tags {

  .field {
    margin: 0;
  }

  .tag-name-form {
    text-align: center;

    .tag-name-field {
      width: 50%;
      margin-bottom: 20px;
      @media screen and (max-width: $break-point) {
        width: 100%;
      }
    }
  }

  .tag {
    @include flex_v_center();

    border: 1px solid white;
    padding: 5px 10px;
    margin: 8px;
    background: white;
    border-radius: 3px;

    input {
      margin-right: 10px;
    }
    label {
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .tag-delete-link {
    text-align: right;
    margin: -4px 10px 16px 0;
    cursor: pointer;
  }
}


.works-refine-box {
  text-align: center;
  margin-bottom: 30px;

  margin-top: -30px;
  @media screen and (max-width: $break-point) {
    margin-top: -10px;
  }

  #refine-open {
    color: black;
    font-size: 1.3rem;
  }
  
  #refine-box-tags {
    background: white;
    margin-top: 10px;
    padding: 30px;

    a {
      color: black;
      font-size: 1.3rem;
      margin-right: 10px;
    }
    .active {
      font-weight: bold;
      font-size: 1.4rem;
    }
    .sep {
      color: lightgray;
    }
  }
}

.work-tags {
  padding: 20px 40px;
  text-align: center;

  a {
    font-size: 1.3rem;
    margin: 0 6px;
  }
  .sep {
    color: lightgray;
  }
}