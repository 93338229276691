.mylinks {
  display: flex;
  justify-content: center;
  padding: 5px;
  padding-top: 30px;

  a {
    max-width: 120px;
    font-size: 1.2rem;
    padding: 6px 8px 4px 8px;
    @media screen and (max-width: $break_point) {
      width: 20%;
      font-size: 1.1rem;
      padding: 6px 0px 4px 0px;
    }

    overflow: hidden;
    background: white;
    flex-grow: 1;
    text-align: center;
    margin: 3px;
    color: black;
    text-decoration: none;

    &:hover {
      background: $color_primary;
      color: white;
    }

    div {
      font-size: 1.2rem;
    }
  }

  .active {
    background: $color_primary;
    color: white;
  }
}