.profile {
  @include flex_v_center();
  justify-content: center;
  max-width: 90%;
  margin: 40px auto;
  
  @media screen and (max-width: $break_point) {
    flex-direction: column;
    margin: 40px auto 20px auto;
  }

  &__avator {
    img {
      width: 300px;
      max-width: 100%;
    }
  }

  &__info {
    text-align: center;
    min-width: 50%;
    max-width: 60%;
    margin-top: 0;
    @media screen and (max-width: $break_point) {
      min-width: 100%;
      max-width: 100%;
      margin-top: 20px;
    }

    .name {
      font-size: 2.0rem;
      font-weight: 600;
      padding: 0 10px;
      padding-bottom: 6px;
      a {
        color: $color_text_main;
      }
    }
  
    .body {
      margin: 0 auto;
      margin-top: 20px;
      font-size: 1.3rem;
      max-width: 70%;
      a {
        color: $color_text_main;
      }
    }

    .info {
      margin: 0 auto;
      margin-top: 20px;
      font-size: 1.1rem;
      max-width: 90%;
    }

    .status {
      border-collapse: separate; 
      border-spacing: 30px 0;
      
      margin: 40px auto 20px auto;
      @media screen and (max-width: $break_point) {
        margin: 20px auto;
      }

      th {
        color: $color_text_main;
        font-size: 1.4rem;
        width: 33%;
      }
      td {
        color: $color_text_sub;
        font-size: 1.4rem;
        a {
          color: $color_text_main;
        }
      }
    }

    .profile__links {
      margin-top: 20px;
      a {
        font-size: 1.3rem;
      }
      .sep {
        color: #dfdfdf;
      }
    }
  }  
}


.artist-profile {
  padding: $base_margin 0;
  text-align: center;

  .name {
    font-size: 2.0rem;
    font-weight: 600;
    padding: 0 10px;
    padding-bottom: 20px;
  }
}