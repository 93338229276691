.profile-side-stream {
  background: white;

  margin-top: 40px;
  padding: 20px 0;
  border-radius: 4px;

  @media screen and (max-width: $break_point) {    
    margin-top: 0;
    padding: 10px 0;
    border-radius: 0;
  }

  &__avator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    @media screen and (max-width: $break_point) {
      // flex-direction: row;
    }

    img {
      width: 200px;
      @media screen and (max-width: $break_point) {
        width: 140px;
      }
    }

    .name {
      font-size: 1.6rem;
      font-weight: 600;
      margin: 16px 0 0 0;

      @media screen and (max-width: $break_point) {
        font-size: 1.5rem;
      }

      a {
        color: $color_text_main;
      }
    }
  }

  &__menu {
    margin-top: 10px;
    padding: 0 20px;

    .row {
      @include flex_v_center();
      color: $color_primary;
      padding: 4px 10px;
      border-top: 1px solid #efefef;

      a {
        flex: 2;
        display: block;
        font-size: 1.5rem;
        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        background-color: $color_background;
      }
      &:last-child {
        border-bottom: 1px solid #efefef;
      }
    }      
  }
}