.invitation-navigation-menu {
  background: #eee;
  text-align: center;
  padding: 20px 0;

  li {
    display: inline-block;
    @media screen and (max-width: $break_point) {
      display: block;
    }
  
    margin: 0 2rem;
    padding: 4px; 
    min-width: 80px;

    &.active {
      font-weight: 600;
    }
    &.title {
      color: $color_text_sub;
    }
    a {
      color: $color_text_main;
    }
  }
}

.invitation-title {
  margin-top: $base_margin;
  @media screen and (max-width: $break-point) {
    margin-top: $base_margin;
  }
}

.invitation-header {
  text-align: center;
  font-size: 1.6rem; 
  padding: 40px 0; 
  color: black;
}

.invitation-desc {
  font-size: 1.3rem;
  color: gray;
  margin: 20px 0;
}

.invitation-link {
  a {
    @include square_button(140px);
    padding: 15px 20px;
    font-weight: 600;
  }
}

.select-recommender {
  select {
    border: 1px solid #eee;
    background: #F4F4F4;
  }
  margin-bottom: 20px;
}

.invitations {
  .invitation {
    margin-top: 20px;

    input {
      border: 1px solid #eee;
      background: #F4F4F4;
      width: 100%;
      padding: 5px;
    }
    .meta {
      color: gray;
      font-size: 1.3rem;
    }
  }
}