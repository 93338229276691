
.timeline-item, 
.grid-sizer { 
  width: calc(33.33% - #{$masonry_gutter});
  @media screen and (max-width: $break-point) {
    width: calc(50% - #{$masonry_gutter_sp});
  }
}

.timeline-item {
  margin-bottom: $masonry_gutter - 20px;
  @media screen and (max-width: $break-point) {
    margin-bottom: $masonry_gutter_sp;
  }
}

.gutter-sizer {
  width: $masonry_gutter;
  @media screen and (max-width: $break-point) {
    width: $masonry_gutter_sp;
  }
}


.timeline-item {
  background: white;
  text-align: center;
  padding: 10px;
  overflow: hidden;

  .image-wrapper {
    position: relative;
    min-height: 100px;
    
    img {
      width: 100%;
      background: #eee;
    }
    .status {
      position: absolute;
      bottom: 0;
      right: 0;
      background: white;
      padding: 2px;
    }
  }

  .title {
    font-size: 1.6rem;
    margin: 10px 0;
    a {
      color: $color_text_main;
    }
  }

  .body {
    font-size: 1.3rem;
    color: $color_primary;
    border-bottom: 1px solid $color_primary;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .info {
    font-size: 1.3rem;
    .name {
      margin-right: 10px;
      a {
        color: $color_primary;
      }
    }
    .date {
      color: $color_primary;
    }
  }
}
