.notification-wrapper {

  .unread-label{
    margin-top: 20px;
    @media screen and (max-width: $break_point) {
      margin-top: 10px;
    }

    text-align: center;
    color: red;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .notification-popup-wrapper{
    @include flex_center();
    position: relative;

    .notification-popup {
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, .5);
      
      width: 80%;
      max-width: 300px;
      max-height: 300px;
  
      overflow-y: scroll;
      overflow-x: hidden;
  
      padding: 5px 0;
      text-align: left;
      font-size: 1.1rem;
      background: #333;
      border-radius: 4px;
      color: white;
  
      position: absolute;
      left: 50%;
      top: 0px;
      transform: translateX(-50%);
  
      display: inline-block;
      z-index: 10;
    }  
  }

  .notification-clear {
    text-align: center;    
    a {
      padding: 10px 0;
      display: block;
    }
  }
}

.notification-item {
  @include flex_v_center();
  padding: 10px;
  border-bottom: 1px solid #555;
  color: white;

  .unread {
    margin-right: 10px;
    font-size: 0.5rem;
    color: red;
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    background: rgb(99, 95, 95);
  }
}
