
.stream-side-title {
  margin-top: 43px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 1.4rem;
  text-align: center;

  display: block;
  @media screen and (max-width: $break_point) {
    display: none;
  }
}

.stream-side-list {
  border-radius: 4px;
  background: white;

  a:hover {
    text-decoration: none;
  }

  .stream-side-cell {
    @include flex_v_center();

    border-bottom: 1px solid #ddd;
    font-size: 1.3rem;
    margin: 0 10px;
    color: $color_text_main;

    img {
      flex: 0 1 auto;
      height: 60px;
      width: 60px;
      margin: 10px;
      margin-left: 0;
    }

    .info {
      flex: 1 2 auto;
      font-size: 1.0rem;
      color: #333;
      overflow: hidden;
    }
  }

  display: block;
  @media screen and (max-width: $break_point) {
    display: none;
  }
}

.stream-side-large-list {
  border-radius: 4px;

  a:hover {
    text-decoration: none;
  }

  .stream-side-large-cell {
    background: white;
    margin: 24px 0;
    word-break: break-all;

    img {
      display: inline-block;
      width: 100%;
      height: calc(100% * 3/4);
      margin: 0 auto;
    }
    .info {
      padding: 10px;
      overflow: hidden;
    }
    .info-title {
      font-size: 1.3rem;
      font-weight: 600;
      color: #333;
    }
    .info-body {
      margin-top: 10px;
      font-size: 1.2rem;
      color: #333;
    }
  }

  display: block;
  @media screen and (max-width: $break_point) {
    display: none;
  }
}