.search-box{
  padding-top: 40px;
  padding-bottom: 40px;

  .search-form {
    text-align: center;

    input[type="text"] {
      width: 30%;
      vertical-align: middle;
      font-size: 1.3rem;
      padding: 6px;
      margin-right: 4px;
      border: solid 1px #ddd;
      border-radius: 4px;
    }

    input[type="submit"] {
      width: 60px;
      vertical-align: middle;
      font-size: 1.3rem;
      padding: 5px;
      border-radius: 4px;
 
      &:hover {
        background: #84C0DA;
      }
    }
  }
}