#artists-search-box {
  .form_area {
    margin-top: 20px;
  }

  .artist__text {
    margin-bottom: 20px;
    margin-left: 0;
  }

  .suggest {
    position: relative;

    .name_list {
      position: absolute;
      z-index: 10;
      border: solid 1px black;
      background-color: white;
      overflow-x: hidden;
      width: 100%;
      border-radius: 4px;

      p {
        padding: 10px;

        .name_jp {
          font-size: 0.8rem;
        }

        &:hover {
          background-color: #efefef;
          font-weight: 600;
          cursor: pointer;
        }
      }

      p+p {
        border-top: solid 1px #ccc;
      }

      .name_list-remained {
        background: #efefef;
        text-align: center;
        font-size: 0.9rem;
        padding: 3px;
      }
    }
  }

  .selected_id {
    text-align: right;
    font-size: 0.8rem;
    margin: 2px;
  }
}