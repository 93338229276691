.comments {
  margin: 20px;
}

.comment {
  margin-bottom: 20px;

  .comment-block {
    display: flex;

    img {
      flex: 0 1 auto;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      margin-right: 6px;
    }  
    .comment-block__user {
      font-weight: 600;
      color: $color_text_main;
    }
    .comment-block__body {
      flex: 1 2 auto;
      background: #f2f3f5;
      padding: 8px 10px;
      border-radius: 3px;
      font-size: 1.3rem;  
    }
  }

  .comment-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 40px;
    font-size: 1.2rem;
    a {
      color: $color_text_sub;
    }
  }
}

.comment-form {
  margin-top: 20px;

  textarea {
    &::placeholder {
      color: #ccc;  
    }
    width: calc(100% - 20px);
    border: 1px solid lightgray; 
    padding: 8px 10px;
    border-radius: 3px;
    font-size: 1.3rem;
    background: #f2f3f5;
  }

  input[type="submit"] {
    @include square_button(80px);
  }
}
