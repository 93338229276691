.event {
  @include clearfix();
  margin-bottom: 60px;

  &__image {
    @media screen and (max-width: $break-point) {
      float: left;
      width: 100%;
      margin-bottom: 10px;
    }
    @media screen and (min-width: $break-point) {
      float: left;
      width: 200px;
    }
    img {
      width: 100%;
    }    
  }

  &__info {
    @media screen and (min-width: $break-point) {
      margin-left: 220px;
    }
  }
  &__title {
    a {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
  &__description {
    margin-top: 10px;
    font-size: 1.3rem;
  }
  &__date {
    margin-top: 6px;
    font-size: 1.1rem;
    color: gray;
    text-align: right;
  }
  &__tags {
    margin-top: 0px;
  }
  &__status {
    font-size: 1.0rem;
    background-color: #eee;
    color: #000;
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 4px;
  }
}

.event-video {
  margin: 40px auto;
  width: 600px;
  @media screen and (max-width: $break-point) {
    width: 100%;
  }
}

.event-new-link {
  margin: 40px auto 0 auto;
  a {
    margin: 0 auto;
    display: block;
    @include square_button(180px);
    padding: 6px 16px;
  }
}

.event-help-link {
  text-align: center;
  margin: 30px 0;
  a {
    text-decoration: underline;
  }
}


.draft_desc {
  text-align: center;
  background-color: #efefef;
  color: $color_text_main;
  padding: 10px 0;
  margin: 10px 0;

  &.warning {
    padding: 16px;
    color: red;
    background-color: #FFFDDD;
    font-weight: bold;
    font-size: 1.3rem;
  }
}


.event-links {
  @include clearfix();
  background: white;
  border-bottom: 1px solid $color_border;
  padding: 5px;
  padding-left: 20px;
  text-align: left;
  margin-bottom: 40px;
}
.event-admin-links, .event_menu-links {
  float: right;
  color: #ccc;
}

.event-main-image {
  background: #F7F7F7;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  img {
    width: 100%;
    max-width: 600px;
    vertical-align: bottom;
  }
  .carousel .item img{ 
    height: 500px;   
  }
}

.event-title {
  font-size: 1.8rem;
  border: 0;
}

.event-sub-title {
  font-size: 1.4rem;
}

.event-body {
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0 20px;
  margin: 0 auto;
  word-break: break-all;

  max-width: 70%;
  @media screen and (max-width: $break-point) {
    max-width: 100%;
  }
}

.event-files {
  margin-top: 20px;
  border-top: 1px dotted #ccc;
  .event-file {
    border-bottom: 1px dotted #ccc;
    padding: 8px 0;
  }  
}

.event-pickup-users {
  @include clearfix();
  width: calc(100% - #{$masonry_gutter});
  margin-left: $masonry_gutter;

  @media screen and (max-width: $break-point) {
    width: calc(100% - #{$masonry_gutter_sp});
    margin-left: $masonry_gutter_sp;
  }
}

.event-pickup-user {
  float: left;

  @media screen and (min-width: $break-point) {
    width: calc(20% - #{$masonry_gutter});
    margin-right: $masonry_gutter;
    margin-bottom: $masonry_gutter - 20px;
    &:nth-child(5n+1) {
      clear: both;
    }
  }

  @media screen and (max-width: $break-point) {
    width: calc(50% - #{$masonry_gutter_sp});
    margin-right: $masonry_gutter_sp;
    margin-bottom: $masonry_gutter_sp;
    &:nth-child(2n+1) {
      clear: both;
    }
  }

  .image {
    text-align: center;
    img {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        object-fit: cover;     
    }
  }
  .name {
    text-align: center;
    a {
      color: black;
      font-size: 1.3rem;
    }
  }
}


.event-showmore-link {
  clear: both;
  text-align: right;
  font-size: 1.6rem;

  margin: 40px;
  @media screen and (max-width: $break-point) {
    margin-right: 20px;
  }
}

.event-gallery-function-links {
  margin: 40px auto;
  text-align: center;
  color: #ccc;
}

.event-users-desc {
  text-align: center;
  font-size: 1.2rem;
}

.event-user-icons {
  padding: 10px;
  border-top: 1px solid #000000;

  .even-user-icon {
    float: left;    
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}


.event-user-comment {
  margin-bottom: 20px;

  .event-user-comment-title {
    margin: 10px 0;
    a {
      color: black;
    }
  }

  .event-user-comment-block {
    display: flex;

    img {
      flex: 0 1 auto;
      width: 140px;
      margin-right: 10px;
    }  
    .event-user-comment-body {
      flex: 1 2 auto;
      background: #f2f3f5;
      padding: 8px 10px;
      border-radius: 3px;
      font-size: 1.3rem;  
      a {
        font-weight: 600;
      }
    }
  }

  .event-user-comment-info {
    margin-top: 8px;
    margin-left: 38px;
    font-size: 1.2rem;
    text-align: right;
  }
}


.event-news-items {
  @include clearfix();

  .event-news-item {
    float: left;
    @media screen and (max-width: $break-point) {
      margin-right: 20px;
      width: calc(50% - 20px);
    }
    @media screen and (min-width: $break-point) {
      margin-right: 40px;
      width: calc(25% - 40px);
    }
    margin-bottom: 40px;

    a {
      color: $color_text_main;
      img {
        width: 100%;
      }
    }
    .info {
      .title {
        font-weight: 600;
        font-size: 1.4rem;
      }
    }
  } 
}
