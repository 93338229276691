.terms {
  padding: 0 20px;
  display: flex;

  flex-direction: row;
  @media screen and (max-width: $break-point) {
    display: block;
  }

  .contents {
    width: 80%;
    @media screen and (max-width: $break-point) {
      width: 100%;
    }
  
    h1 {
      font-weight: bold;
      font-size: 1.8rem;
      text-align: center;
      border: none;

      margin: 60px 0;
      @media screen and (max-width: $break-point) {
        margin: 40px 0;
      }
    }
    h2 {
      font-weight: bold;
      border: none;
      margin: 0;
      padding: 0;
      margin-bottom: 10px;
    }
    .indent {
      padding-left: 20px;
    }
    font-size: 1.4rem;
    line-height: 1.8;
    color: #3c3c3c;

    .block {
      margin-bottom: 30px;
    } 
  }

  .links {
    flex-grow: 0;
    width: 320px;
    margin-top: 60px;
    padding-left: 20px;
    font-size: 1.3rem;

    @media screen and (max-width: $break-point) {
      width: 100%;
      margin-top: 20px;
      padding-left: 0px;
    }

    .title {
      margin-bottom: 4px;
      font-weight: bold;
      font-size: 1.2rem;

      display: block;
      @media screen and (max-width: $break-point) {
        display: none;
      }
    }
    ul {
      background: #F4F4F4;
      border-radius: 8px;
      padding: 20px 10px 20px 10px;
    }
    li {
      list-style-type: disc;
      margin-left: 20px;
    }
  }
}