.carousel{
  margin: 0 auto;
  max-width: 600px;

  .item img{    
    width: 100%;
    object-fit: contain;
  }

  .slick-thumb{
    position:unset;
    padding: 15px 0;
    
    li{
      width: 80px;
    }

    li img{
      width: 100%;    
    }
  }
}