.news-wrapper {
  border-top: 1px solid $color_border;
  margin: 0 20px;

  padding-top: $base_margin;
  @media screen and (max-width: $break_point) {
    padding-top: $base_margin / 2;
  }
}

.news__main { 
  @include clearfix();
  overflow: hidden;

  .images {
    float: left;
    width: calc(40% - 30px);
    img {
      width: 100%;
      margin-bottom: 30px;
    }

    @media screen and (max-width: $break_point) {
      float: none;
      width: 100%;
      img {
        width: 100%;
        margin-bottom: 30px;
      }  
    }
  }

  .contents {
    margin-left: 40%;
    background: $color_background;
    padding: 40px;
    color: $color_text_main;
    line-height: 1.8;
    margin-bottom: 0;

    @media screen and (max-width: $break_point) {
      clear: both;
      margin-left: 0;
      margin-bottom: $base_margin / 2;
    }

    .title {
      font-size: 2.0rem;
    }
    .body {
      margin-top: 40px;
      font-size: 1.5rem;
    }
    .date {
      margin-top: 20px;
      font-size: 1.4rem;
      color: $color_text_sub;
      text-align: right;
    }
  }
}

.news-published-desc {
  margin: 0px auto 50px auto;
  text-align: center;
  font-size: 1.3rem;
  color: gray;
}

@media screen and (max-width: $break_point) {
  .news-items {
    @include clearfix();
    margin-bottom: $base_margin;
  
    .news-item {
      display: flex;
      width: 100%;
      overflow: hidden;
      margin-bottom: 40px;
      
      a {
        color: $color_text_main;
      }
      .thumbnail {
        width: 30%;
        img {
          width: 100%;
        }
      }
      .info {
        margin-left: 16px;
        flex: 1;
        .title {
          font-weight: 600;
          font-size: 1.4rem;
        }
        .body {
          font-size: 1.2rem;
          color: $color_text_sub;
        }
      }
    }
  }
}

@media screen and (min-width: $break_point) {
  .news-items {
    @include clearfix();    
    margin-bottom: $base_margin;
    
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    .news-item {
      width: 33.33%;
      padding: 20px;
      padding-bottom: 40px;
      overflow: hidden;
      
      a {
        color: $color_text_main;
      }
      img {
        width: 100%;
      }
      .info {
        .title {
          font-weight: 600;
          font-size: 1.4rem;
        }
        .body {
          font-size: 1.2rem;
          color: $color_text_sub;
        }
      }
    }
  }
}
