.pickup-artist {
  @include clearfix();
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.pickup {
  background: white;
  border-radius: 4px;
  padding: 10px 0;

  @media screen and (max-width: $break_point) {
    border: 0;
    border-radius: 0;
  }

  &__title {
    font-size: 1.1rem;
    margin-left: 10px;
    color: $color_text_sub;
  }

  &__name {
    margin-left: 10px;
    margin-bottom: 10px;
    a {
      color: $color_text_main;
    }  

    color: $color_primary;
    font-size: 1.5rem;
    font-weight: 600;
  }

  &__works {
    padding: 0 16px;
    display: flex;
  }

  &__work {
    text-align: center;
    width: 33.33%;

    .work__image {
      text-align: center;
      img {
        width: 95%;
        height: 95%;
      }
    } 
    .work__title {
      font-size: 1.3rem;
      a {
        color: $color_text_sub; 
      }
    }
  }
}

.more-artists {
  margin-top: 10px;
  margin-right: 10px;
  text-align: right; 
  font-size: 1.3rem;  
  a {
    color: $color_text_sub;
  }
}
