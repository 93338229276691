.top-logo {
  text-align: center;
}

.top-login {
  text-align: center; 
  margin: 40px 0;

  .login-large {
    border: 1px solid $color_primary;
    padding: 8px 40px;
    color: $color_primary;
    font-size: 1.8rem;
  
    &:hover {
      color: white;
      background: $color_primary;
      text-decoration: none;
    }
  }
}

.login {
  border: 1px solid $color_primary;
  padding: 6px 12px;
  margin-right: 20px;
  color: $color_primary;

  &:hover {
    color: white;
    background: $color_primary;
    text-decoration: none;
  }
}

.howto {
  text-align: center;
  margin: 60px 0;

  .title {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .desc {
    margin: 0 auto; 
    margin-top: 20px; 
    max-width: 80%;
  }
}


.public-news-wrapper {
  background: $color_background;
 
  padding-top: 60px;
  @media screen and (max-width: $break-point) {
    padding-top: 30px;
  }
}


.landing-subtitle {
  font-weight: 600;
  text-align: center;
  font-size: 1.8rem;
  margin: 80px 0 80px 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.landing-subtitle:before,
.landing-subtitle:after {
  content: "";
  border-top: 1px solid #bbb;
  flex-grow: 1;
}
.landing-subtitle:before {
  margin-right: 2rem;
}
.landing-subtitle:after {
  margin-left: 2rem;
}


.landing-showmore {
  text-align: right;
  margin-top: 30px;
  margin-right:  20px;
}


.landing-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 10px;

  .item {
    margin: 0 auto;
    margin-bottom: 40px;
    
    width: calc(33.33%);
    padding: 20px;
    @media screen and (max-width: $break-point) {
      width: calc(50%);
      padding: 10px;
    }


    .image {
      background: #f7f7f7;
      text-align: center;
      margin-bottom: 10px;
    
      height: 240px;
      @media screen and (max-width: $break-point) {
        height: 180px;
      }
    
      img {
        height: 100%;
        max-width: 100%;
        object-fit: contain;
      }
    }

    .title {
      font-size: 1.4rem;
      color: #2c2e31;
      font-weight: 600;
      line-height: 1.43;

      a {
        color: #2c2e31;
      }
    }
    .body {
      font-size: 1.2rem;
      line-height: 1.67;
      color: #707980;
      margin-top: 10px;
    }
    .date {
      font-size: 1.1rem;
      color: #707980;
      text-align: right;
      margin-top: 10px;
    }
  }
}

.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.truncate3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}


.landing-galleries {
  background: $color_background;
  padding: 20px 0;

  .subtitle {
    font-weight: 600;
    text-align: center;
    font-size: 1.8rem;
    margin: 40px 0;
    color: #2c2e31;
  }
}

.landing-gallery-block {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 20px;

  .item {
    @include flex_v_center();
    background: white;
    overflow: hidden;
    margin-bottom: 20px;
    
    width: calc(33.33% - 15px);
    @media screen and (max-width: $break-point) {
      width: calc(50% - 15px);
    }
    @media screen and (max-width: $break_point_narrow) {
      width: 100%;
    }
    
    .image {
      @include flex_v_center();
      padding: 10px;
      img {
        height: 90px;
        width: calc(90px / 5 * 7);
      }
    }

    .info {
      padding-right: 10px;
      .title {
        font-size: 1.4rem;
        color: #2c2e31;
        font-weight: 600;
      }
      .body {
        margin-top: 6px;
        font-size: 1.2rem;
        color: #707980;
      }
    }
  }
}
