.header {
  background: white;

  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex_header;
  width: 100%;
  height: $header_height;

  @media screen and (max-width: $break-point) {
    height: $sp_header_height;
  }

  .content {
    @include site_max_width();
    border-bottom: 1px solid $color_border;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: $break-point) {
      @include clear_site_max_width();
    }
  }

  .logo {
    flex-grow: 1;
    padding-left: 30px;
    text-align: left;
    img {
      width: 60px;
      margin-top: 10px;
    }
  }

  .menu-items {
    flex-grow: 0;
    display: block;

    @media screen and (max-width: $break_point) {
      display: none;
    }

    .menu-item {
      color: $color_text_main;
      padding: 0 30px;
      font-size: 1.5rem;
      list-style: none;
    }
  }
  
  .nav-drawer {
    position: relative;
    flex-grow: 0;
    font-size: 1.6rem;
    padding-right: 0;
    color: $color_text_sub;
    display: none;

    @media screen and (max-width: $break_point) {
      display: block;
      padding-right: 30px;
    }
  }
}



#nav-content {
  .nav-inner {
    margin-right: 40px;
    text-align: right;
    h2 {
      margin: 20px 0 5px 0;
      border: 0;
    }
    .links {
      font-size: 1.4rem;
      a {
        margin-top: 10px;
        color: white;
        display: block;
      }
    }
    .links-small {
      font-size: 1.2rem;
    }
  }
}


// Drawer
// https://saruwakakun.com/html-css/reference/nav-drawer

/*チェックボックス等は非表示に*/
.nav-unshown {
  display:none;
}

/*閉じる用の薄黒カバー*/
#nav-close {
  display: none;/*はじめは隠しておく*/
  position: fixed;
  z-index: $zindex_drawer_close;
  top: 0;/*全体に広がるように*/
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;
  transition: .3s ease-in-out;
}

/*中身*/
#nav-content {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex_drawer_content;/*最前面に*/
  width: 80%;/*右側に隙間を作る（閉じるカバーを表示）*/
  max-width:80%;/*最大幅（調整してください）*/
  height: 100%;
  background: #2C2E30;/*背景色*/
  transition: .3s ease-in-out;/*滑らかに表示*/
  -webkit-transform: translateX(-105%);
  transform: translateX(-105%);/*左に隠しておく*/
}

/*チェックが入ったらもろもろ表示*/
#nav-input:checked ~ #nav-close {
  display: block;/*カバーを表示*/
  opacity: .5;
}

#nav-input:checked ~ #nav-content {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);/*中身を表示（右へスライド）*/
  box-shadow: 6px 0 25px rgba(0,0,0,.15);
}


