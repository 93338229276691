html,
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // ios 光らない
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
}

body {
  position: relative;
  font-size: 1.6em;
  line-height: 1.6;
  font-weight: normal;
  background-color: #FFF;
  font-family: "Roboto","Hiragino Sans","Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3","メイリオ","Meiryo","ＭＳ Ｐゴシック","Helvetica Neue","Helvetica","Arial",sans-serif;
  width: 100%;
  min-height: 100%;
  color: $color_text_main;
}

a {
  text-decoration: none;
  color: $color_primary;

  &:hover {
    text-decoration: underline;
  }
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}



h1, h2 {
  font-weight: 600;
  font-size: 1.4rem;
  padding-bottom: 4px;
  border-bottom: 1px solid $color_border;

  margin-top: $base_margin;
  margin-bottom: $base_margin / 2;
  @media screen and (max-width: $break-point) {
    margin-top: $base_margin / 2;
  }
}

h1.half-margin {
  margin-bottom: $base_margin / 2;
}
h1.half-top-margin {
  margin-top: $base_margin / 2;
}

.info-table {
  font-size: 1.5rem;

  th {
    color: $color_text_sub;
    width: 140px;
    padding: 4px 0;
    text-align: left;
  }
}

.spacer {
  margin-bottom: $base_margin;
  @media screen and (max-width: $break-point) {
    margin-bottom: $base_margin / 2;
  }
}

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}

/* IEでのinputのクリアボタン非表示 */
input::-ms-clear {
  visibility:hidden
}
input::-ms-reveal {
  visibility:hidden
}
