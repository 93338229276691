.admin {


  .artists {
    $btn-color: #84c0da;
    font-size: 1.3rem;


    .filter button {
      margin: 0 10px 10px 0;
      padding: 2px 5px;
      color: $btn-color;
      background: white;
      border: solid 1px $btn-color;
      transition: 0.3s;
      display: inline-block;
    }

    .filter button:hover {
      color: white;
      background: $btn-color;

    }

    .filter button.active {
      color: white;
      background: $btn-color;
    }

    table {
      border: solid 1px gray;
      border-collapse: collapse;
      width: 100%;
      padding: 20px;
      margin-bottom: 40px;
    }

    thead {
      background-color: #ddd;
    }

    tr {
      border: solid 1px gray;
    }

    tr.master {
      background: rgba(243, 129, 129, 0.2);
    }

    tr.child {
      background: rgba(149, 225, 211, 0.2);
    }

    td,
    th {
      padding: 5px;
      text-align: center;
      border: solid 1px gray;
    }

    td.children {
      text-align: left;
    }

    td.name {
      min-width: 150px;
      text-align: left;
    }

    .hide {
      display: none;
    }

    input {
      padding: 3px;
      border: solid 1px gray;
    }

    input::placeholder {
      color: #aaa;
    }

    input.input-master_id {
      width: 60px;
      text-align: center;
    }

    input.input-name {
      width: 100%;
    }

    .btn {
      cursor: pointer;
      margin: 3px 10px;
      padding: 2px 5px;
      color: $btn-color;
      background: white;
      border: solid 1px $btn-color;
      font-size: 1.3rem;
      transition: 0.3s;
      display: inline-block;
    }

    .btn:hover {
      background: $btn-color;
      color: white;
      cursor: pointer;
    }

    .name-update {
      margin-left: 0px;
      color:$btn-color;
      background-color: transparent;
    }

    .name-cancel {
      margin-left: 10px;
      color:$btn-color;
      background-color: transparent;
    }


    .destroy-btn {
      margin: 3px 10px;
      padding: 2px 5px;
      color: red;
      background: white;
      border: solid 1px red;
      transition: 0.3s;
      display: inline-block;
    }

    .destroy-btn:hover {
      background: red;
      color: white;
    }

    .child_name {
      display: inline-block;
      font-size: 0.5rem;
      background-color: $btn-color;
      color: white;
      margin: 5px;
      padding: 2px 5px;
      border-radius: 5px;
    }

    .hide {
      display: none;
    }

    .add-form {
      margin-bottom: 20px;

      input {
        display: block;
        padding: 3px;
        border: solid 1px gray;
        text-align: center;
        margin-bottom: 10px;
      }

      input::placeholder {
        font-size: 0.1rem;
      }

      .form--name {
        width: 100%;
      }

      .form--master_id {
        width: 100%;
      }

      .form-btn {
        padding: 6px;
        color: $btn-color;
        background: white;
        border: solid 1px $btn-color;
        margin: 0;
      }

      .form-btn:hover {
        background: $btn-color;
        color: white;
      }
    }
  }//artists

  // loader 
  .loading {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading .spiner {
    color: #ffffff;
    font-size: 20px;
    margin: 200px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  @-webkit-keyframes load4 {

    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }

  @keyframes load4 {

    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }


}