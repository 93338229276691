.site-news {
  margin: 30px;
  padding: 20px;
  background-color: #FFF6D4;
  border-radius: 10px;

  &__title {
    font-weight: bold;
    font-size: 1.3rem;
  }
  &__body {
    margin-top: 10px;
    font-size: 1.3rem;
  }
  &__links {
    margin-top: 20px;
    font-size: 1.3rem;
    text-align: center;
  }
}


.stream-wrapper {
  display: flex;
  justify-content: center;

  @media screen and (max-width: $break_point) {
    flex-direction: column;
  }
  
  .stream__main {
    width: 65%;
    @media screen and (max-width: $break_point) {
      width: 100%;
    }
  }

  .stream__spacer {
    width: 5%;
    max-width: 60px;
    @media screen and (max-width: $break_point) {
      width: 0;
    }
  }

  .stream__side {
    width: 30%;
    @media screen and (max-width: $break_point) {
      width: 100%;
    }
  }
}

.stream-item {
  @include clearfix();
  width: 100%;
  // border: 1px solid #ddd;
  border-radius: 4px;
  word-break : break-all;

  @media screen and (max-width: $break_point) {
    border: 0;
    border-radius: 0;
  }

  margin: 0 auto;
  margin-bottom: 60px;
  background: white;

  .user-info {
    @include flex_v_center();
    img {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      margin: 10px;
      margin-right: 8px;
    }
    .name {
      font-size: 1.3rem;
      font-weight: 600;
      a {
        color: $color_text_main;
      }      
    }
    .date {
      color: gray;
      font-size: 1.1rem;
      text-align: right;
      flex: 1;
      margin-right: 16px;
    }
  }

  .edit-link {
    text-align: right;
    font-size: 1.2rem;
    margin: 16px;
    margin-top: -16px;
    a {
      color: $color_text_sub;
    }
  }

  .public-label {
    text-align: right;
    font-size: 1.2rem;
  }

  .title {
    border-top: 1px solid #ddd;
    padding: 16px;
    a {
      color: black;
      font-weight: 600;
    }
  }

  .body {
    padding: 16px;
    font-size: 1.3rem;
    color: black;
  }

  .image-wrapper {
    padding: 10px 0;
    text-align: center;
    img {
      max-height: 380px;
      max-width: 98%;
    }
  }

  .comments-title {
    // border-bottom: 1px solid #ddd;
  }  
}

.stream-image-slider {
  padding-top: 15px;
  img {
    width: 60px;
    cursor: pointer;
  }
  img + img {
    margin-left: 10px;
  }
}

.stream-video-error {
  text-align: center;
  color: red;
  font-size: 0.8rem;
  padding: 10px;
}

@import "stream_side";
@import "stream_filter";
