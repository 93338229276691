.sns-box {
  @include flex_center;

  .fb-share-button {
    margin-right: 6px;
  }
}

.stream-sns-box {
  text-align: center;
  padding-top: 10px;

  .fb-share-button {
    margin-right: 6px;
  }
  
  .fb_iframe_widget span{
    vertical-align:inherit !important;
  }
}