.footer {
  background: white;

  color: $color_text_main;
  height: 130px;
  font-size: 1.3rem;
  @include flex_v_center();

  .content {
    @include site_max_width();
    border-top: 1px solid #DDDDDD;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: $break_point) {
      display: none;
    }

    .logo {
      padding-left: 30px;
      font-size: 0.8rem;
      img {
        width: 60px;
      }
    }
  
    .links {
      text-align: right;
      padding-right: 30px;
      flex-grow: 1;
      a {
        color: $color_text_main;
        padding: 0 10px;
      }
      .sep {
        color: $color_text_sub;
      }
    }  
  }

  .content-sp {
    width: 100%;
    height: 100%;
    border-top: 1px solid #ddd;
    font-size: 0.8rem;

    display: none;
    @media screen and (max-width: $break_point) {
      @include flex_center();
      flex-direction: column;
    }
    img {
      width: 60px;
    }
  }
}
