.profile-side {
  background: #f2f3f5;
  padding: 30px 0 20px 0;
  @media screen and (max-width: $break_point) {
  }

  &__avator {
    text-align: center;
    img {
      width: 200px;
      @media screen and (max-width: $break_point) {
        width: 240px;
      }
    }
  }

  &__info {
    text-align: center;
    width: 100%;
    margin-top: 10px;
      
    .name {
      font-size: 2.0rem;
      font-weight: 600;
      padding: 0 10px;
      padding-bottom: 6px;
      a {
        color: $color_text_main;
      }
    }
  
    .body {
      margin: 0 auto;
      margin-top: 20px;
      font-size: 1.3rem;
      max-width: 90%;
      a {
        color: $color_text_main;
      }
    }

    .info {
      margin: 0 auto;
      margin-top: 20px;
      font-size: 1.1rem;
      max-width: 90%;
    }
  
    .status {
      margin: 40px auto 20px auto;
      border-collapse: separate; 

      th {
        width: 33.33%;
        color: $color_text_main;
        font-size: 1.2rem;
        padding: 0 10px;
        white-space: nowrap;
      }
      td {
        color: $color_text_sub;
        font-size: 1.4rem;
        a {
          color: $color_text_main;
        }
      }
    }

    .menu-button {
      margin-top: 20px;
      @include flex_center();
      a {
        margin: 10px;
      }
    }
  }

  &__links {
    a {
      display: block;
      font-size: 1.4rem;
    }
  }
}