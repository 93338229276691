.follow-button {
  button {
    @include square_button(150px);

    &:focus {
      outline: 0;
    }
  }
}

.menu-button > a,
.showmore,
.contact > a {
  @include square_button(150px);
}
